<template>
	<div class="card-container">
		<div class="card-image">
			<img :src="image" />
		</div>
		<div class="card-content">
			<div class="date">
				{{ date }}
			</div>
			<div class="title">
				{{ title }}
			</div>
			<div class="description">
				{{ description }}
			</div>
			<a class="more-link" target="_blank" :href="linkByLang">
				{{ $t('blog.moreLink') }}
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Card',
	props: {
		date: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			required: true,
		},
		linkHU: {
			type: String,
			required: true,
		},
		linkEN: {
			type: String,
			required: true,
		},
		image: {
			type: String,
			required: true,
		},
	},
	components: {},
	data: () => ({}),
	created() {},
	computed: {
		linkByLang() {
			if (this.$i18n.locale == 'hu') {
				return this.linkHU;
			} else if (this.$i18n.locale == 'en') {
				return this.linkEN;
			}
		},
	},
	methods: {},
};
</script>
 
<style lang='scss' scoped>
.card-container {
	display: inline-block;
	height: 600px;
	width: 100%;
	background: $white;
	box-shadow: 0px 4px 8px rgba(75, 46, 170, 0.1);
	border-radius: 10px;
	overflow: hidden;
	position: relative;
}

.card-image {
	width: 100%;
	height: 250px;
	background-color: $black;
}

.card-image > img {
	width: 100%;
	height: 101%;
	object-fit: cover;
}

.card-content {
	padding: 20px;
	height: 350px;
}

.date {
	font-size: 10px;
	line-height: 24px;
	color: rgba(0, 33, 79, 0.39);
}

.title {
	font-size: 20px;
	color: $dark-blue;
	height: 100px;
	width: 100%;
	overflow: hidden;
	white-space: pre-line;
	margin-top: 15px;
	font-family: 'HKNova-Bold', sans-serif;
	font-weight: 700;
}

.description {
	width: 100%;
	height: 100px;
	margin-top: 15px;
	color: $text-blue;
	font-size: $text-m;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 24px;
	white-space: pre-line;
}

.more-link {
	bottom: 40px;
	position: absolute;
	font-size: $text-l;
	font-family: 'HKNova', sans-serif;
	font-weight: 400;
	cursor: pointer;
	transition: $transition-global;
	color: #4b2eaa;
	&:hover {
		color: $link-hover-color;

		&:after {
			background: $link-hover-color;
		}
	}

	&:after {
		content: '';
		width: 10px;
		height: 17px;
		background: #4b2eaa;
		display: inline-block;
		mask-image: url('/images/icons/bb-icon_arrow-right.svg');
		margin: 0 0 0 5px;
		top: 2px;
		position: relative;
		transition: $transition-global;
	}
}

.right-arrow {
	display: inline-block;
	transform: rotate(-90deg);
	font-size: $text-l;
}
</style>
